.divider {
  width: 98%;
  height: 1px;
  background-color: #ffffff;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: -10px !important;
}

.divider2 {
  width: 98%;
  height: 1px;
  background-color: #ffffff;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
}
