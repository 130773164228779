.header {
  margin: 20px 0px;
  letter-spacing: 1px;
  background: #f3f3f3;
  width: fit-content;
  padding: 10px 20px 10px 10px;
  color: gray;
  font-size: 18px;
  border-radius: 3px;
  font-weight: 600;
}

.searchInputBox {
  margin: 20px;
  border-color: black;
  padding: 8px 16px;
  border-radius: 4px;
}

.addNewProdButtonContainer {
  margin: 20px;
  background: #eb0a0a;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  /* box-shadow: 0px 0px 5px  gray; */
}
.addNewProdButtonContainerBlack {
  margin: 20px;
  padding: 8px 16px;
}

.addNewProdButtonContainer:hover {
  margin: 20px;
  background: #eb0a0a;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  box-shadow: 0px 0px 3px #eb0a0a;
}
.addNewProdButton {
  color: #ffff;
  font-weight: 600;

  text-decoration: none;
}
.addNewProdButtonBlack {
  color: "black";
  font-weight: 600;

  text-decoration: none;
}
.productRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.productRowHeading {
  background: #eb0a0a;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.productRowHeading p {
  color: #ffff;

  font-weight: 500;
  font-size: 16px;
}

.productDetailStyle {
  width: 70px;
  overflow-wrap: anywhere;
}
/* 
.underline{
    background: gray;
    height: 0.1px
}*/
.deleteIcon {
  background: transparent;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 18px;
}
.editButton {
  border: none;
  color: dodgerblue;
  text-decoration: none;
}
.editButtonContainer {
  background: none;
  border: 1px solid dodgerblue;
}
.viewPrice {
  text-decoration: none;
  color: dodgerblue;
}

.divider {
  width: 98%;
  height: 1px;
  background-color: #ffffff;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}
