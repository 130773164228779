:root {
  --dashboardheadingfontsize: 32px;
  --dashboardsubheadingfontsize: 25px;
  --cardheadingfontsize: 25px;
  --staticsfontsize: 35px;
  --staticsubfontsize: 20px;
  --staticfontcolor: #0303e9;
  --aboutheadingfontcolor: #252525;
  --pfontcolor: #717171;
  --pfontsize: 22px;
  --about-2-h1fontsize: 50px;
  --about-2-box-h3-fontsize: 22px;
  --about-2-box-h3-fontcolor: #717171;
  --about-2-box-h1-fontsize: 45px;
  --about-2-box-h1-fontcolor: #252525;
  --about-2-box-p-fontsize: 18px;
  --about-2-box-p-fontcolor: #717171;
}

.bill {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}

.bill-container {
  width: 100%;
  height: 100%;
  padding: 0.5% 3%;
  align-items: center;
  justify-content: center;
}

.bill-heading {
  color: var(--aboutheadingfontcolor);
  font-style: normal;
  font-weight: 500;
  font-size: var(--dashboardheadingfontsize);
  line-height: 1px;
  text-align: left;
}

.input-container {
  padding: 8px;
}

.input-box {
  height: 50px;
  width: 75%;
}

.bill-subheading {
  color: var(--aboutheadingfontcolor);
  font-style: normal;
  font-weight: 300;
  font-size: var(--dashboardsubheadingfontsize);
  line-height: 10px;
  text-align: left;
  padding-left: 11px;
}

.button-container {
  text-align: left;
  margin-top: 16px;
  padding: 8px;
  height: 100%;
}

.button {
  height: 100%;
  width: 100%;
}

.my-table {
  padding-top: 38px;
}

.discount {
  margin-top: 20px;
}
