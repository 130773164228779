.Dashboard {
  padding: 20px;
}

.dashboard-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  padding-left: 30px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 5px;
  margin-bottom: 10px;
  background-color: #e2e2e2;
}

.card-heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.dashboard-card {
  width: 100%;
  height: 80%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.card-content {
  margin-top: -40px;
}

.static-count {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 3px;
}

.static-subheading {
  font-size: 16px;
  color: #6b7280;
  margin-top: 10px;
}

.date-select {
  width: 40%;
  margin-bottom: 10px;
}

.dashboard-table-card {
  width: 200%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.dividerDashBoard {
  width: 98%;
  height: 1px;
  background-color: #ffffff;
  margin: 8px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-top: -20px;
  margin-right: 30px !important;
  margin-left: 10px !important;
}
