.mainContainer {
  position: relative;
}

.mainContainer::before {
  content: "";
  background-image: url("https://miro.medium.com/v2/resize:fit:1400/1*uTKB9urNtTlVn1We-hIKFg.jpeg");
  background-size: cover;
  background-position: center;
  opacity: 0.9; /* Adjust the opacity value (0.0 to 1.0) */
  filter: blur(1px); /* Adjust the blur value as needed */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.welcomeTitle {
  background: #eb0a0a;
  font-size: 40px;
  margin: 0;
  color: #ffff;
  padding: 20px 0px;
  text-align: center;
  font-weight: 600;
}
.buttonDiv button {
  margin-top: 20px;
  background: #eb0a0a;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 10px 20px;
  cursor: pointer;
}
.signUpBtn {
  text-decoration: none;
  color: #eb0a0a;
  font-weight: bold;
}
.userNamePassWord {
  font-weight: 600;
}
.userNamePassWordInput {
  padding: 5px 10px;
  border: 1px solid gray;
  border-radius: 8px;
}
.title1 {
  font-weight: 500;
  font-size: 25px;
}
.signInDiv {
  flex-direction: row;
  align-items: center;
}
.credentialsDiv {
  text-align: center;
  align-items: center;
}

.modelTittle {
  text-align: left;
}

.modelSubTittle {
  text-align: left;
}
