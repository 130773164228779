.addBannerTitle {
  font-size: 24px;
  font-weight: 500;
  color: gray;
  background: #f3f3f3;
  width: fit-content;
  padding: 4px 8px;
  border-radius: 3px;
  margin-left: 20px;
}
.addProductFormContainer {
  width: 400px;
  margin-left: 20px;
}
.addBannerFieldStyle {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.addBannerFieldtext {
  display: flex;
  justify-content: space-between;
}
.addBannerFieldName {
  font-size: 18px;
  font-weight: 500;
}
.checkBoxNames {
  width: 200px;
}
.addProductBtn {
  background: #eb0a0a;
  border: none;
  padding: 8px 16px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px gray;
  color: #ffff;
  font-weight: 600;
  cursor: pointer;
}

.addBannerInputStyle {
  width: 180px;
}

.bill-subheading {
  padding-top: 10px;
}

.subtotal {
  display: flex;
  justify-content: space-between;
}

.discount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.discount-select {
  display: flex;
  align-items: center;
}

.gst-amount {
  display: flex;
  align-items: center;
  margin-left: 16px; /* Adjust the value as needed */
}

/* style={{ width: "400px", marginLeft: "20px" }} */

.pdf {
  background-color: "white";
  width: 100%;
  min-height: "auto";
  margin-left: "auto";
  margin-right: "auto";
}
