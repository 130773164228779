/* Header wrapper */
.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

/* Header card */
.header-card {
  background-color: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  height: 90px;
}

/* Header logo */
.header-logo {
  margin-top: -10px;
  margin-left: -10px;
  cursor: pointer;
}

/* Header user */
.header-user {
  margin-top: 3px;
}

/* Header email */
.header-email {
  margin-right: 10px;
}

/* Additional styles as needed */
