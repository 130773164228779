@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900;700&display=swap");

body,
html {
  margin: 0;
  font-family: "Encode Sans", sans-serif !important;
}

@font-face {
  font-family: "Encode Sans";
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans";
  font-weight: 100;
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans";
  font-weight: 300;
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans";
  font-weight: 500;
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans";
  font-weight: 600;
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans";
  font-weight: 700;
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}
@font-face {
  font-family: "Encode Sans";
  font-weight: 800;
  src:
    local("Encode Sans"),
    url("../public/assets/EncodeSans-VariableFont_wdth.ttf") format("truetype");
}

code {
  font-family: "Encode Sans", sans-serif;
}

.ant-btn {
  background-color: #4087cb;

  color: white;
}

.ant-table th {
  font-size: 16px;
}

.ant-table tr {
  font-size: 14px;
}
