:root {
  --aboutheadingfontsize: 120px;
  --aboutheadingfontcolor: #252525;
  --pfontcolor: #717171;
  --pfontsize: 22px;
  --about-2-h1fontsize: 50px;
  --about-2-box-h3-fontsize: 22px;
  --about-2-box-h3-fontcolor: #717171;
  --about-2-box-h1-fontsize: 45px;
  --about-2-box-h1-fontcolor: #252525;
  --about-2-box-p-fontsize: 18px;
  --about-2-box-p-fontcolor: #717171;
}

.components-layout-demo-responsive .logo {
  height: 100px;
  margin: 160000px;
  background: rgba(28, 200, 143, 0.2);
}

.about-heading {
  color: var(--aboutheadingfontcolor);
  font-style: normal;
  font-weight: 700;
  font-size: var(--aboutheadingfontsize);
  line-height: 144px;
  text-align: left;
}

.custom-menu-item .ant-menu-item-title {
  padding-top: 12px; /* adjust padding value as needed */
}

/* General styles for menu and submenu */
.custom-menu, .custom-menu .ant-menu-submenu-title {
  background-color: #4087cb;
  color: #fff;
}

.custom-menu .ant-menu-item {
  color: #fff;
}

/* When a submenu is selected */
.custom-menu .ant-menu-submenu-selected .ant-menu-submenu-title,
.custom-menu .ant-menu-item-selected {
  background-color: #fff !important;
  color: #3483eb !important; /* Your desired text color */
}

/* When an item or submenu is active (hover or focus) */
.custom-menu .ant-menu-submenu-title:active,
.custom-menu .ant-menu-item:active {
  background-color: #fff;
  color: #3483eb;
}

/* Hover state */
.custom-menu .ant-menu-item:hover,
.custom-menu .ant-menu-submenu-title:hover {
  background-color: #fff;
  color: #3483eb;
}
